section.popular-cities {
  background: rgba(white, 0.9);

  .popular-cities__title {
    display: block;
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: bold;
    color: #FFFFFF;
    margin: 0;
  }
}
