.home-partials-search {
  input,
  select,
  [type=submit] {
    box-shadow: none;
    border: 1px solid #e4e6e6;
  }

  [type=submit] {
    margin: 0;
  }
}
