@import './assets/huurstunt/scss/utilities/common';
@import './partials/advertisement';
@import './partials/list-slider';
@import './partials/popular-cities';

#route-to-home {
  background: transparent;

  form[name='advanced_search'] {
    input,
    select {
      height: 55px;
    }
  }

  .hero-search-wrap {
    position: relative;
    @include boxed-widget--clean;

    .website-of-the-year {
      border-radius: 50%;
      transform: rotate(15deg);
      position: absolute;
      display: block;
      top: -10px;
      right: -20px;
    }
  }

  footer .below-footer {
    opacity: 0.9;
  }

  #wrapper {
    .home-partials-search > .flash-bag {
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      right: 0;

      .alert {
        width: 100vw;
        background: $white;
      }
    }
  }
}

// mobile
@include media-breakpoint-down(sm) {
  #route-to-home {
    .home-partials-search {
      padding-bottom: 0;

      .hero-search-wrap {
        border: 0 !important;
      }
    }

    section.popular-cities {
      padding-top: 0 !important;
    }

    #wrapper .flash-bag {
      position: relative;
    }
  }
}
