@import './assets/huurstunt/scss/utilities/common';

.rental-watermark-disable-image-drag {
  user-select: none;
  pointer-events: none;
}

.rental-nowatermark {
  display: grid;
  height: 100%;
  width: 100%;
  border-radius: .5rem 0 0 .5rem;

  .left-images {
    height: 100%;
  }
}

.rental-watermark-overlay-single-images,
.rental-watermark-overlay-multiple-images,
.rental-watermark-overlay-list-image,
.rental-card-watermark-overlay-image {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: .5rem 0 0 .5rem;

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__icon {
    position: absolute;
    top: 46%;
    left: 0;
    right: 0;
    margin: auto;
    flex-grow: 0;
    color: $white;
    z-index: 2;
    border-radius: 50%;
    background-color: $font-primary-color;
    border: 0.188rem solid $white;
    height: 4rem;
    width: 4rem;
    padding: 1rem 1.188rem;
    font-size: 1.5rem;
  }

  &__paragraph {
    position: absolute;
    top: 53%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 600;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: $font-primary-color;
    padding: 0 1rem;
  }

  .left-images {
    height: 100%;
  }
}

.rental-watermark-overlay-list-image {

  &__icon {
    top: 33%;
    border: 0.125rem solid $white;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.5rem 0.688rem;
    font-size: 1.063rem;
  }

  &__paragraph {
    font-size: 0.75rem;
  }
}

.rental-card-watermark-overlay-image {

  &__icon {
    top: 33%;
    border: 0.125rem solid $white;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.5rem 0.688rem;
    font-size: 1.063rem;
  }

  &__paragraph {
    font-size: 0.75rem;
  }
}

.rental-watermark-overlay-multiple-images {

  &__icon {
    top: 38%;
  }

  &__paragraph {
    top: 54%;
  }
}

.rental-watermark-overlay-single-images {
  position: unset;

  &__link {
    position: unset;
  }

  &__icon {
    top: 42%;
  }

  &__paragraph {
    top: 58%;
  }
}

@include media-breakpoint-down(lg) {

  .rental-watermark-overlay-multiple-images {

    &__icon {
     top: 37%;
    }

    &__paragraph {
      top: 54%;
      font-size: 1.2rem;
    }
  }
}

@include media-breakpoint-down(md) {

  .rental-watermark-overlay-single-images {

    &__paragraph {
      font-size: 1.2rem;
    }
  }

  .rental-watermark-overlay-list-image {

    &__icon {
     top: 30%;
    }

    &__paragraph {
      top: 49%;
      font-size: 1.2rem;
    }
  }
}

@include media-breakpoint-down(sm) {

  .rental-watermark-overlay-single-images,
  .rental-watermark-overlay-multiple-images {

    &__icon {
      top: 40%;
      border: 0.125rem solid $white;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.5rem 0.688rem;
      font-size: 1.063rem;
    }

    &__paragraph {
      top: 58%;
      font-size: 0.75rem;
    }
  }
}
