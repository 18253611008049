section.advertisement {
  .text {
    max-width: 35rem;
    display: block;
    margin: auto;
    text-align: center;

    h2 {
      font-size: 29px;
      line-height: 1.2;
      margin-bottom: 2rem;
    }

    h2,
    p {
      color: $white;
    }

    a.btn {
      background: $white;
      margin: 2.5rem auto 0 auto;
      cursor: pointer;
    }
  }
}
