@import './assets/huurstunt/scss/utilities/common';

#route-to-property_details,
#route-to-property_contact {
  .row.list-layout {
    align-items: center;
  }
}

.rental-card {
  height: 100%;
  width: 100%;

  span {
    text-decoration: none;
  }

  // boxes
  > .boxed-widget--clean {
    width: inherit;
    height: inherit;
    padding-bottom: 13px;
    overflow: hidden;

    &.animate {
      @include boxed-widget--transition;
    }

    .listing-city a {
      transition: none;

      &:hover {
        font-weight: bold;
      }
    }
  }

  .rental-card--content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    > span.top-content,
    > span.bottom-content {
      display: inline-flex;
      flex-flow: column nowrap;
      justify-content: space-between;
    }

    > span.bottom-content {
      padding-bottom: 3.2rem;
    }

    img {
      width: inherit;
    }
  }

  // sublinks
  .sub-links {
    position: absolute;
    height: inherit;
    width: inherit;
    pointer-events: none;

    > * {
      z-index: 10;
    }

    .listing-like-top {
      top: 0.5rem;
      left: 0.5rem;
    }

    .listing-city {
      position: relative;
      width: 100%;
      top: calc(100% - 4rem);
      padding: .9rem 15px;
      display: block;
    }

    a,
    span.like-icon {
      pointer-events: auto;
    }
  }

  // mainlink
  a.main-link {
    display: flex;
    height: 100%;
  }

  // features
  .features {
    display: flex;
    flex-flow: row wrap;

    .features__item > span {
      padding: .9rem 0;
      display: block;
    }
  }

  span.listing-img-wrapper,
  span.list-img-slide {
    display: block;
    width: inherit;
    height: inherit;
  }

  // image
  span.listing-img-wrapper {
    position: relative;
    overflow: hidden;

    .list-img-slide {
      height: 347px;
    }

    img {
      object-fit: cover;
      object-position: 50% 50%;
      min-width: 100%;
      min-height: 100%;
      max-width: initial;
      max-height: initial;
    }
  }

  //title
  span.listing-name {
    color: #2d3954;
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    margin: 1.2rem 1.5rem;
    line-height: 1.5rem;
  }
}

@include media-breakpoint-up(md) {
  .rental-card {
    .features .features__item:nth-of-type(4n + 1),
    .features .features__item:nth-of-type(4n + 2) {
      background: $row-light-grey;
    }
  }
}

@include media-breakpoint-down(sm) {
  .rental-card {
    .features .features__item:nth-of-type(odd) {
      background: $row-light-grey;
    }

    .sub-links .listing-city {
      background: $row-light-grey;
    }
  }
}

@include media-breakpoint-down(xs) {
  .rental-card.mobile-full-width .boxed-widget--clean {
    border-radius: 0;
  }
}

.article-housing {
  background-color: $white;
  border-radius: .5rem;
  border: 1px solid $border-light-grey;
  overflow: hidden;
  height: 100%;
  position: relative;
  transition: all .2s ease-in-out;
  .listing-like-top {
    left: .5rem;
    top: .5rem;
  }
  .housing-card {
    img {
      width: 100%;
      object-fit: cover;
      height: 250px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      gap: 1rem;
      padding: 1rem;
      header {
        display: flex;
        flex-direction: column;
        gap: .25rem;
        width: 100%;
        h3 {
          display: inline-block;
          margin-bottom: 0;
          font-size: 1rem;
          line-height: 1.4;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
        .bottom-content {
          display: flex;
          gap: .5rem;
          div {
            display: flex;
            align-items: center;
            gap: .25rem;
            &:not(:last-child) {
              padding-right: .5rem;
              border-right: 1px solid $border-light-grey;
            }
            p {
              margin: 0;
              font-size: .875rem;
            }
          }
        }
      }
      footer {
        p {
          margin: 0;
        }
        .housing-card-rent {
          font-weight: 600;
          font-size: .875rem;
          color: $font-primary-color;
        }
      }
    }
  }
}

.article-housing {
  box-shadow: 0 0 10px #79727233;
  &:hover {
    box-shadow: 0 0 20px #79727233;
  }
}

@include media-breakpoint-down(md) {
  .article-housing {
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-down(sm) {
  .list-layout-mobile {
    position: relative;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .list-layout  {
    display: block;
    position: relative;
    white-space: nowrap;
    margin: auto;
    .list-layout-card {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      width: calc(90% - 20px);
      padding: 0 0 .25rem;
      margin-right: 0.5rem;
    }
  }
}
