.list-slider {
  .slick-slider {
    .slick-arrow {
      opacity: 0.9;

      &:before {
        background-color: rgba(35, 119, 145, 1);
      }
    }

    .slick-slide {
      padding: 10px;
      margin: 0 11px;
      height: 100%;
    }
  }

  .rental-card {
    > div,
    .rental-card--content {
      z-index: 0;
    }

    .listing-name {
      min-height: 50px;
    }
  }
}
